<template>
  <!-- 抽盒商品 -->
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
          </el-input>
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        v-loading="tabLoading" :row-style="{ cursor: 'pointer' }" :span-method="arraySpanMethod">
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <!-- 道具 -->
	<grade-Detail ref="grade-Detail" @refresh="getTableData"></grade-Detail>
   <!-- <el-dialog :title="dialogTitle" :append-to-body="true" :visible.sync="dialogFormVisible"
      :top="ruleForm.propType == 4?'8vh':'15vh'" width="30%" @close="resetForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
        <el-form-item label="累计消费" prop="consume" v-if="'consume' in ruleForm"
          :rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.consume"></el-input>
        </el-form-item>
        <el-form-item label="月消费" prop="monthConsume" v-if="'monthConsume' in ruleForm"
          :rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.monthConsume"></el-input>
        </el-form-item>
        <el-form-item label="等级" prop="level" v-if="'level' in ruleForm"
          :rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.level"></el-input>
        </el-form-item>
        <el-form-item label="道具名称" prop="propName" v-if="'propName' in ruleForm"
          :rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}">
          <el-input v-model="ruleForm.propName"></el-input>
        </el-form-item>
        <el-form-item label="道具类型" prop="propType" v-if="'propType' in ruleForm"
          :rules="{required: true, message: '请至少选择一个道具卡类型', trigger: 'change'}">
          <el-select v-model="ruleForm.propType" placeholder="请选择">
            <el-option v-for="item in propTypes" :key="item.value" :label="item.key" :value="item.value"
              v-if="item.value != 1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="道具数量" prop="propNum" v-if="'propNum' in ruleForm"
          :rules="{required: true, message: '道具数量不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.propNum"></el-input>
        </el-form-item>
        <el-form-item label="有效天数" prop="validDay" v-if="'validDay' in ruleForm"
          :rules="{required: true, message: '有效天数不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.validDay"></el-input>
        </el-form-item>
        <el-form-item label="优惠券面额" prop="money" v-if="'money' in ruleForm && ruleForm.propType == 4"
          :rules="{required: true, message: '优惠券面额不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.money"></el-input>
        </el-form-item>
        <el-form-item label="优惠券类型" prop="couponsType" v-if="'couponsType' in ruleForm && ruleForm.propType == 4"
          :rules="{required: true, message: '请至少选择一个优惠劵类型', trigger: 'change'}">
          <el-select v-model="ruleForm.couponsType" placeholder="请选择">
            <el-option v-for="item in couponsTypes" :key="item.value" :label="item.key" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用门槛" prop="doorSill" v-if="'doorSill' in ruleForm && ruleForm.propType == 4"
          :rules="{required: true, message: '使用门槛不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.doorSill"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">{{dialogTitle == '新增'?'确 定':'保 存'}}
        </el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { mapState } from 'vuex'
export default {
  name: 'userGrade',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
	"grade-Detail": (resolve) => require(["@/views/modules/install/userGradeDetail"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'propTypes': state => state.dictList ? state.dictList.prop_type : [],  //道具类型
      'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [],  //优惠劵类型
    })
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
      },
      butLoading: false,
      tabLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', fixed: 'left' },
        { label: '累计消费', prop: 'consume', align: 'left' },
        { label: '月消费', prop: 'monthConsume', align: 'left', },
        { label: '等级', prop: 'level', align: 'left', },
        // { label: '道具名称', prop: 'propName', align: 'left', 'show-overflow-tooltip': true },
        // {
        //   columnType: 'fun', label: '道具类型', prop: 'propType', align: 'left', width: '120', formatData: (e) => {
        //     let ele = this.propTypes.find(ele => ele.value == e)
        //     return ele ? ele.key : e
        //   }
        // },
        // { label: '有效天数', prop: 'validDay', align: 'left', width: '150', 'show-overflow-tooltip': true },
        // { label: '面额', prop: 'money', align: 'left', width: '100', 'show-overflow-tooltip': true },
        // { label: '门槛', prop: 'doorSill', align: 'left', width: '100', 'show-overflow-tooltip': true },
        // {
        //   columnType: 'fun', label: '优惠劵类型', prop: 'couponsType', align: 'left', width: '120', 'show-overflow-tooltip': true, formatData: (e) => {
        //     let ele = this.couponsTypes.find(ele => ele.value == e)
        //     return ele ? ele.key : e
        //   }
        // },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList, },
            { type: 'text', text: '删除', fn: this.deleteList, }
          ],
        },
      ],
      tableData: [],
      //弹框
      dialogTitle: '新增',
      // dialogFormVisible: false,
      ruleForm: {
        consume: '', //累计消费
        monthConsume: '',//月消费
        level: '',	//等级
        // propName: '',//道具名称
        // propType: '', //道具类型
        // propImg: '',//道具图片
        // propNum: '', //道具数量
        // validDay: '', //有效天数
        // money: '', //优惠券面额
        // doorSill: '', //使用门槛
        // couponsType: '', //优惠券类型
      },
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableData()
    },
    //合并行或列
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1 || columnIndex === 2) {
        var spanArr = this.getSpanArr(this.tableData, 'level')
        const _row = spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 处理合并行的数据
    getSpanArr (data, spanKey) {
      var spanArr = []
      var pos = ''
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          spanArr.push(1)
          pos = 0
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i][spanKey] === data[i - 1][spanKey]) {
            spanArr[pos] += 1
            spanArr.push(0)
          } else {
            spanArr.push(1)
            pos = i
          }
        }
      }
      return spanArr
    },
    //导出
    exportExcel () { },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/userLevel/findUserLevelList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      })
    },
    //新增列表
    addList () {
		console.log('新增列表')
		this.$refs['grade-Detail'].init()
    },
    //编辑列表
    editList (row) {
      this.$refs['grade-Detail'].init(row.id)
    },
    //删除
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/userLevel/removeUserLevel', { id: row.id }).then(({ data: result }) => {
          this.getTableData()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      }).catch(_ => { })
    },
    //重置清除校验
    resetForm () {
      Object.assign(
        this.ruleForm,
        this.$options.data().ruleForm
      )
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
  },
}
</script>

<style lang="less" scoped>
</style>